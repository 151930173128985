import React, {useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import EquipmentServicesBody from "./equipmentServicesBody";
import LoadingIndicator from "../../../common/loadingIndicator";
import moment from "moment";
import DateRangeEquipmentFilter from '../../../common/form-filters/DateRangeEquipmentFilter';

/**
 * Equipment Services Component
 * @returns {JSX.Element}
 * @constructor
 */
const EquipmentServices = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [filters, setFilters] = useState({
        date_from: moment().startOf('month').format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        equipment_id: '',
        isLoading: false,
        isSubmitted: false,
    });

    return (
        <>
            <Breadcrumb title="Equipment Services" parent="Equipment"/>
            <DateRangeEquipmentFilter {...{filters, setFilters, setTableData, OnSubmitUrl:"getEquipmentServices", isEquipmentRequired: false}}/>
            <EquipmentServicesBody
                filters={filters}
                setFilters={setFilters}
                tableData={tableData}
                setTableData={setTableData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
            />
            <LoadingIndicator isLoading={filters.isLoading}/>
        </>
    );
}

export default EquipmentServices;
