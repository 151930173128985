import {AccordionItem} from 'react-light-accordion';
import RevenuePerShiftChart from './charts/revenuePerShiftChart';
import FeetPerShiftChart from './charts/feetPerShiftChart';
import NonBillableChart from './charts/nonBillableChart/nonBillableChart';
import DrillingChart from './charts/drillingChart';
import DowntimeChart from './charts/downtimeChart/downtimeChart';
import ShiftUtilizationChart from './charts/shiftUtilization';
import HeadcountPerShiftChart from './charts/headcountPerShiftChart/headcountPerShiftChart';
import styled from 'styled-components';

const AccordionItemContainer = styled.div`
    & .accordion-item .title {
        font-size: 1.5rem;
    }
`

const ChartsContainer = styled.div`
    gap: 60px;
`;

const OperationalDashboardAccordion = ({title, data, withSummaryTooltips = false}) => {

    return (
        <AccordionItemContainer>
            <AccordionItem className="card-header bg-primary" title={title}>
                <div className="p-2">
                    <ChartsContainer className="d-flex overflow-auto">
                        {
                            data.revenue_per_shift && (
                                <div style={{minWidth: '800px'}}>
                                    <RevenuePerShiftChart chartData={data.revenue_per_shift} />
                                </div>
                            )}
                        {
                            data.footage_per_shift && (
                                <div style={{minWidth: '800px'}}>
                                    <FeetPerShiftChart chartData={data.footage_per_shift} />
                                </div>
                            )
                        }
                        {
                            data.non_billable && (
                                <div style={{minWidth: '800px'}}>
                                    <NonBillableChart chartData={data.non_billable} withSummaryTooltip={withSummaryTooltips} />
                                </div>
                            )
                        }
                        {
                            data.drilling && (
                                <div style={{minWidth: '800px'}}>
                                    <DrillingChart chartData={data.drilling} />
                                </div>
                            )
                        }
                        {
                            data.labor_per_shift && (
                                <div style={{minWidth: '800px'}}>
                                    <HeadcountPerShiftChart chartData={data.labor_per_shift} withSummaryTooltip={withSummaryTooltips} />
                                </div>
                            )
                        }
                        {
                            data.downtime && (
                                <div style={{minWidth: '800px'}}>
                                    <DowntimeChart chartData={data.downtime} withSummaryTooltip={withSummaryTooltips} />
                                </div>
                            )
                        }
                        {
                            data.shift_utilization && (
                                <div style={{minWidth: '800px'}}>
                                    <ShiftUtilizationChart chartData={data.shift_utilization} />
                                </div>
                            )
                        }
                    </ChartsContainer>
                </div>
            </AccordionItem>
        </AccordionItemContainer>
    )
}

export default OperationalDashboardAccordion;
