import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import FormSelect from "../../forms/form-control/FormSelect";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../utils/stateHelpers";
import {isEmpty} from "lodash";
import moment from "moment";
import FormInputDate from "../../forms/form-control/FormInputDate";

/**
 * PayPeriodFormFilter component
 * @returns {JSX.Element}
 * @constructor
 */
const PayrollDateRangeFilter = (prop) => {
    const {setFilters, setTableData, OnSubmitUrl} = prop;
    const {register, handleSubmit, errors, setValue, watch} = useForm();
    const [payrollDates, setPayrollDates] = useState([]);
    const [payPeriods, setPayPeriods] = useState([]);
    const [endDate, setEndDate] = useState(0);

    const pay_period_id = watch('pay_period_id');

    // get list of pay periods
    useEffect(() => {
        //get payroll dates
        apiClient
            .get('getPayrollDatesLockIgnored')
            .then(response => {
                setPayrollDates(
                    response.data.map(
                        ({date, is_current}) => ({
                            id: date, name: date + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });

        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                let year = Number(moment(new Date(response.data.end_date)).format('YYYY'));
                setEndDate(year);
            });

        apiClient
            .post('getPayPeriodsByYearPlusPreviousYear', {'year': endDate})
            .then(response => {
                setPayPeriods(
                    response.data.map(
                        ({id, start_date, end_date, is_current}) => {

                            if (is_current) {
                                setValue('pay_period_id', id);
                                setDate(id);
                            }

                            return {
                                id: id, name: start_date + ' - ' + end_date + (is_current ? ' (Current)' : '')
                            }
                        }

                    )
                );
            });
    }, [endDate, setValue]);

    const setDate = (value) => {
        if (value) {
            apiClient
                .post('getPayPeriodsById', {'id': value})
                .then(response => {
                    let start = response.data.start_date;
                    let end = response.data.end_date;
                    let starRaw = new Date(start);
                    let endRaw = new Date(end);
                    let startDate = moment(starRaw).format('yyyy-MM-DD');
                    let endDate = moment(endRaw).format('yyyy-MM-DD');

                    document.getElementById('date_from').value = startDate;
                    document.getElementById('date_to').value = endDate;
                });
        }
    }

    const onSubmit = (data) => {
        if (!isEmpty(data['date_from'])) {
            updateStateValue(setFilters,'isLoading', true);
            updateStateValue(setFilters,'isSubmitted', true);

            apiClient
                .post(OnSubmitUrl, data)
                .then(response => {
                    if (isEmpty(response.data)) {
                        setTableData([]);
                        return;
                    }
                    setTableData(response.data);
                })
                .catch(function () {
                    setTableData([]);
                }).then(function () {
                updateStateValue(setFilters,'isLoading', false);
            });
        }
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="row">
                <FormSelect
                    id="payroll_date"
                    label="Payroll Date"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                    options={payrollDates}
                    onChange={(e) => {
                        setValue('pay_period_id', '');
                        setValue('date_from', e.target.value);
                        setValue('date_to', e.target.value);
                    }}
                    register={register({required: false})}
                    errors={errors}
                />
                <FormSelect
                    id="pay_period_id"
                    label="Pay Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                    options={payPeriods}
                    onChange={(e) => {
                            setDate(e.target.value);
                            setValue('payroll_date', '');
                            setValue('pay_period_id', e.target.value);
                        }
                    }
                    value={pay_period_id}
                    register={register('pay_period_id', {required: false})}
                    errors={errors}
                    isControlled
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    register={register({required: false})}
                    onChange={() => {
                        setValue('pay_period_id', '');
                        setValue('payroll_date', '');
                    }}
                    errors={errors}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    register={register({required: false})}
                    onChange={() => {
                        setValue('pay_period_id', '');
                        setValue('payroll_date', '');
                    }}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default PayrollDateRangeFilter;
