import React, {useEffect, useState} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import LoadingIndicator from "../../../common/loadingIndicator";
import apiClient from '../../../../utils/apiClient';
import { isEmpty } from 'lodash';
import EqServiceDistributionEmailList from './email-list/EqServiceDistributionEmailList';

/**
 * EquipmentServicesDistributionList component
 * @returns {JSX.Element}
 * @constructor
 */

const EquipmentServicesDistributionList = () => {
    const [tableData, setTableData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        apiClient
            .get('getEqServicesDistributionEmails')
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }
                setTableData(response.data);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            }).then(function () {
            setIsLoading(false);
        });
    }, [])

    return (
        <>
            <Breadcrumb title="Eq Services Distribution list" parent="Equipment"/>
            <EqServiceDistributionEmailList
                title='Eq Services Distribution list'
                tableData={tableData}
                setTableData={setTableData}
            />
            <LoadingIndicator isLoading={isLoading}/>
        </>
    );
}

export default EquipmentServicesDistributionList;
