import {AccordionItem} from "react-light-accordion";
import React, {useEffect} from "react";
import {FaPlus, FaTrash} from "react-icons/fa";
import InputInTable from "../../../forms/form-control/daily/InputInTable";
import {useFieldArray} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSearchableSelect from "../../../forms/form-control/FormSearchableSelect";
import * as Shared from "../../../common/shared";

const JobChargesAccordionCard = (props) => {
    const {title, fieldArrayName, control, errors, register, setValue, costCodes, fieldArrayValues} = props;
    const {fields, append, remove} = useFieldArray({
        control,
        name: fieldArrayName
    });

    useEffect(() => {
        remove();
        append(fieldArrayValues);
        Shared.toggleAllAccordionItems();
    }, [append, remove, fieldArrayValues]);

    const selectCode = (name, value, index) => {
        apiClient
            .post('getCostCodeByCode', {'code': value})
            .then(response => {
                setValue(`${fieldArrayName}[${index}].revenue_group`, response.data.revenue_group?.name);
                setValue(`${fieldArrayName}[${index}].lost_time_group`, response.data.lost_time_groups?.name);
                setValue(`${fieldArrayName}[${index}].description`, response.data.description);
            });
    }

    return (
        <AccordionItem className="card-header bg-primary" title={title}>
            <div className="card">
                <div className="form-row">
                    <div className="col-sm-12">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>RG</th>
                                        <th>LT</th>
                                        <th>Description</th>
                                        <th>Price</th>
                                        <th>UM</th>
                                        <th>
                                            <FaPlus
                                                color='#22af47'
                                                size={20}
                                                title="Add New"
                                                onClick={() => append({})}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fields.map((item, index) => (
                                        <tr key={item.id}>
                                            <td width={400}>
                                                <FormSearchableSelect
                                                    id={`${fieldArrayName}[${index}].code`}
                                                    options={costCodes}
                                                    rules={{required: "required"}}
                                                    control={control}
                                                    value={parseInt(typeof item.code === 'string' ? item.code : item.code?.value)}
                                                    errors={errors}
                                                    onChange={
                                                        (v) => selectCode(
                                                            `${fieldArrayName}[${index}].code`,
                                                            v,
                                                            index
                                                        )
                                                    }
                                                    customStyles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            width: 400,
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            width: 400,
                                                            position: 'sticky',
                                                        })
                                                    }}
                                                />
                                            </td>
                                            <InputInTable
                                                id={`${fieldArrayName}[${index}].revenue_group`}
                                                name={`${fieldArrayName}[${index}].revenue_group`}
                                                value={item.revenue_group}
                                                register={register({required: {value: true, message: "required"}})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                id={`${fieldArrayName}[${index}].lost_time_group`}
                                                name={`${fieldArrayName}[${index}].lost_time_group`}
                                                value={item.lost_time_group}
                                                register={register({required: false})}
                                            />
                                            <InputInTable
                                                id={`${fieldArrayName}[${index}].description`}
                                                name={`${fieldArrayName}[${index}].description`}
                                                value={item.description}
                                                register={register({required: {value: true, message: "required"}})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                id={`${fieldArrayName}[${index}].price`}
                                                name={`${fieldArrayName}[${index}].price`}
                                                value={item.price}
                                                register={register({required: {value: true, message: "required"}})}
                                                errors={errors}
                                            />
                                            <InputInTable
                                                id={`${fieldArrayName}[${index}].um`}
                                                name={`${fieldArrayName}[${index}].um`}
                                                value={item.um}
                                                register={register({required: {value: true, message: "required"}})}
                                                errors={errors}
                                            />
                                            <td>
                                                <FaTrash
                                                    color='#A51E37'
                                                    size={20}
                                                    title="Remove"
                                                    onClick={() => remove(index)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionItem>
    );
}

export default React.memo(JobChargesAccordionCard);
