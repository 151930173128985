import React, { useEffect, useState } from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import apiClient from "../../../../utils/apiClient";
import {useForm} from "react-hook-form";
import {addObjToArray} from "../../../../utils/arrayHelpers";
import {toast} from "react-toastify";
import FormInputNumber from "../../../forms/form-control/FormInputNumber";
import { isCurrentYear } from '../../../../utils/dateHelpers';
import FormSelect from "../../../forms/form-control/FormSelect";

/**
 * Modal form to create a record
 * @returns {JSX.Element}
 * @constructor
 */
const OperationDashboardDowntimeTargetCreateModal = (props) => {
    const {modal, setModal, title, tableData, setTableData} = props;
    const {register, handleSubmit, errors, setValue} = useForm();
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    const [years, setYears] = useState([]);

    useEffect(() => {
        apiClient
            .get('getFinancialPeriodsYears')
            .then(response => {
                setYears(
                    Object.values(response.data).map((id) => ({value: id, label: id + (isCurrentYear(id) ? " (Current)" : "")}))
                );
            })
    }, []);

    const onSubmit = (data) => {
        apiClient
            .post('adminDowntimeTargets', data)
            .then(response => {
                let objectString = {
                    id: response.data.record.id,
                    year: response.data.record.year,
                    target: response.data.record.target,
                };
                setTableData(addObjToArray(tableData, objectString));
                toast.success(response.data.message);
                toggle();
            });
    }

    const filterSelectionHandler = (e) => {
        const {id, value} = e.target;
        setValue(id, value);
    }

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body" centered={true} size={'md'}>
            <form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ModalHeader toggle={toggle}>{`${title}`}</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormSelect
                            id="year"
                            label="Year"
                            className="col-md-4 mb-4"
                            onChange={filterSelectionHandler}
                            options={years}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputNumber
                            id="target"
                            label="Target"
                            className="col-md-4 mb-4"
                            register={register({
                                required: 'required',
                                min: {value: 0, message: "min value is 0"},
                                max: {value: 3000, message: "max value is 100"},
                            })}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default React.memo(OperationDashboardDowntimeTargetCreateModal);
