import React, {useEffect, useState} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormInputText from "../../../forms/form-control/FormInputText";
import {useForm, Controller} from "react-hook-form";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormInputEmail from "../../../forms/form-control/FormInputEmail";
import FormInputNumber from "../../../forms/form-control/FormInputNumber";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import * as ArrayHelpers from "../../../../utils/arrayHelpers";
import FormSelectPlain from "../../../forms/form-control/FormSelectPlain";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import moment from "moment/moment";
import Creatable from 'react-select/creatable';

/**
 * EditUserModal Modal
 * @returns {JSX.Element}
 * @constructor
 */
const EditUserModal = (props) => {
    const {
        modal, setModal, editUserData, disciplines, userTypes, userId, tableData, setTableData,
        setEditUserModal, editUserModal
    } = props;
    const {register, handleSubmit, errors, reset, control, setValue} = useForm();
    const [selectedUserType, setSelectedUserType] = useState('');
    const [showTimeApprovers, setShowTimeApprovers] = useState(0);
    const [timeApprovers, setTimeApprovers] = useState([]);
    const [selectedSuffix, setSelectedSuffix] = useState('');
    const [selectedTimeEntry, setSelectedTimeEntry] = useState(0);
    const [selectedOffice, setSelectedOffice] = useState('');
    const [selectedDiscipline, setSelectedDiscipline] = useState('');

    const populateTimeApprovers = (setter, timeEntry) => {
        if (timeEntry > 1)
        {
            // unset
            setter([]);
            let link = '';

            if (timeEntry === 2) {
                link = 'shopTimeApprovers';
            }

            if (timeEntry === 3) {
                link = 'officeTimeApprovers';
            }

            apiClient
                .get(link)
                .then(response => {
                    let optionItems = [];
                    response.data.records.forEach(
                        ({user_id, name}) => {
                            optionItems.push({value: user_id, label: name});
                        }
                    );

                    setter(optionItems);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    useEffect(() => {
        if (typeof editUserData !== 'undefined') {
            if (editUserData.time_entry === null) {
                setShowTimeApprovers(0);
            }
        }

        // show time approvers initially if time entry is greater than 1
        if (typeof editUserData !== 'undefined') {
            if (editUserData.time_entry > 1) {
                populateTimeApprovers(setTimeApprovers, editUserData.time_entry);
                setShowTimeApprovers(1);
            } else {
                setShowTimeApprovers(0);
            }
        }
    }, [editUserData, setTimeApprovers, setShowTimeApprovers]);

    useEffect(() => {
        if (typeof editUserData !== 'undefined') {
            setSelectedUserType(editUserData.group_id);
            setSelectedSuffix(editUserData.suffix);
            setSelectedDiscipline(editUserData.discipline_id);
            setSelectedOffice(editUserData.office_region);
            setSelectedTimeEntry(editUserData.time_entry);
        }
    }, [editUserData])

    const renderTimeEntry = (value) => {
        setSelectedTimeEntry(Number(value));
        setValue('time_approvers', []);

        if (Number(value) > 1) {
            populateTimeApprovers(setTimeApprovers, Number(value));
        }

        let types = [2, 3];
        setShowTimeApprovers((types.includes(Number(value))) ? 1 : 0);
    }

    const onSave = (data) => {
        if (data !== '') {
            data['id'] = userId;
            data['time_approver_ids'] = data.time_approvers?.map(item => item.value);

            apiClient
                .post('editUser', JSON.stringify(data))
                .then(response => {
                    reset();
                    const newTableData = ArrayHelpers.replaceObjFromArray(
                        tableData,
                        "user_id",
                        response.data.id,
                        response.data.row
                    );
                    setTableData(newTableData);
                    toast.success("User has been saved.");
                })
                .catch((error) => {
                    console.error(error);
                })
                .then(function () {
                    //close deactivateModal
                    setEditUserModal({...editUserModal, isOpen: false})
                });
        } else {
            errors.showMessages();
        }
    }

    // toggle for open/close the modal form
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-lg modal-body" centered={true}>
            <form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSave)}
            >
                <ModalHeader toggle={toggle}>Edit User</ModalHeader>
                <ModalBody>
                    <div className="form-row">
                        <FormInputText
                            id="first_name"
                            label="First Name"
                            type="text"
                            className="col-md-6 mb-3"
                            value={editUserData ? editUserData.first_name : ''}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputText
                            id="last_name"
                            label="Last Name"
                            type="text"
                            className="col-md-6 mb-3"
                            value={editUserData ? editUserData.last_name : ''}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormSelectPlain
                            label="Suffix"
                            id="suffix"
                            name="suffix"
                            className="col-md-2 mb-3"
                            options={[
                                {value: " ", label: "- Select -"},
                                {value: "Jr", label: "Jr"},
                                {value: "Sr", label: "Sr"}
                            ]}
                            value={
                                selectedSuffix
                                    ?
                                        selectedSuffix
                                    :
                                        editUserData
                                            ?
                                                editUserData.suffix
                                            :
                                                ''
                            }
                            onChange={(e) => setSelectedSuffix(e.target.value)}
                            register={register({required: false})}
                            isControlled={true}
                            errors={errors}
                        />
                        <FormInputEmail
                            id="email"
                            label="Email"
                            type="email"
                            className="col-md-6 mb-3"
                            value={editUserData ? editUserData.email : ''}
                            register={register({required: "required"})}
                            isControlled={true}
                            errors={errors}
                            readOnly
                        />
                        <FormInputNumber
                            id="erp_id"
                            label="VP ID"
                            className="col-md-3 mb-3"
                            value={editUserData ? editUserData.erp_id : ''}
                            register={register({
                                min: {value: 1, message: "VP ID min value is 1"},
                                max: {value: 99999999, message: "VP ID max value is 99999999"},
                                required: "required"
                            })}
                            errors={errors}
                        />
                        <FormSelect
                            id="group_id"
                            label="User Type"
                            className="col-md-6 mb-3"
                            options={userTypes}
                            value={
                                selectedUserType
                                    ?
                                        selectedUserType
                                    :
                                        editUserData
                                            ?
                                                editUserData.group_id
                                            :
                                                ''
                            }
                            register={register({required: "required"})}
                            onChange={(e) => setSelectedUserType(e.target.value)}
                            isControlled={true}
                            errors={errors}
                        />
                        <FormSelect
                            label="Discipline"
                            id="discipline_id"
                            name="discipline_id"
                            className="col-1/2 mb-3"
                            isControlled={true}
                            options={disciplines}
                            value={
                                selectedDiscipline
                                    ?
                                        selectedDiscipline
                                    :
                                    editUserData
                                            ?
                                                editUserData.discipline_id
                                            :
                                                ''
                        }
                            onChange={(e) => setSelectedDiscipline(e.target.value)}
                            register={register({required: false})}
                            errors={errors}
                        />
                        <FormSelectPlain
                            label="Office Region"
                            id="office_region"
                            name="office_region"
                            isControlled={true}
                            className="col-md-2 mb-3"
                            options={[
                                {value: "", label: "- Select -"},
                                {value: "Elko", label: "Elko"},
                                {value: "Phoenix", label: "Phoenix"}
                            ]}
                            value={
                                selectedOffice
                                    ?
                                        selectedOffice
                                    :
                                        editUserData
                                            ?
                                                editUserData.office_region
                                            :
                                                ''
                            }
                            onChange={(e) => setSelectedOffice(e.target.value)}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormInputDate
                            id="hire_date"
                            label="Hire Date"
                            className="col-md-6 mb-3"
                            min={moment(new Date('2000-01-01')).format('YYYY-MM-DD')}
                            value={editUserData ? editUserData.hire_date : ''}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormSelectPlain
                            label="Time Entry User?"
                            id="time_entry"
                            name="time_entry"
                            isControlled={true}
                            className="col-md-6 mb-3"
                            options={
                                [
                                    {value: 1, label: 'No'},
                                    {value: 2, label: 'Shop'},
                                    {value: 3, label: 'Office'}
                                ]
                            }
                            value={
                                selectedTimeEntry
                                    ?
                                        selectedTimeEntry
                                    :
                                        editUserData
                                            ?
                                                (editUserData.time_entry !== null) ? editUserData.time_entry : 1
                                            :
                                                ''
                            }
                            onChange={(e) => renderTimeEntry(e.target.value)}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        { showTimeApprovers > 0 &&
                            <div className="col-md-12 mb-3">
                                <label htmlFor="time_approvers">Time Approvers</label>
                                <Controller
                                    as={Creatable}
                                    id="time_approvers"
                                    options={timeApprovers}
                                    name="time_approvers"
                                    defaultValue={editUserData.time_approvers?.map(
                                        ({id, first_name, last_name}) => ({value: id, label: `${first_name} ${last_name}`})
                                    )}
                                    errors={errors}
                                    control={control}
                                    isMulti
                                />
                            </div>
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">Save</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default EditUserModal;
