import React from "react";
import apiClient from "../../../../utils/apiClient";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {FaRegFilePdf, FaEnvelopeOpenText} from "react-icons/fa";
import moment from "moment";

const EquipmentServicesTable = (
    setFilters, setSelectedRow, setSendReportModal
) => {
    return [
        {
            Header: 'Date',
            accessor: 'date',
            width: 100,
            Cell: (cell) => moment.utc(cell.value, 'YYYY-MM-DD').format('MM/DD/yyyy'),
        },
        {
            Header: 'Last',
            accessor: 'last_name',
        },
        {
            Header: 'First',
            accessor: 'first_name',
        },
        {
            Header: 'Equipment',
            accessor: 'eq_number',
        },
        {
            Header: 'Description',
            accessor: 'eq_description',
        },
        {
            Header: 'SN',
            accessor: 'eq_unique_number',
        },
        {
            Header: 'EQ Hours',
            accessor: 'eq_hours',
            width: 70,
        },
        {
            Header: 'Comp Hrs',
            accessor: 'comp_hours',
            width: 70,
        },
        {
            Header: 'Truck Hrs',
            accessor: 'truck_hours',
            width: 70,
        },
        {
            Header: 'Miles',
            accessor: 'miles',
            width: 70,
        },
        {
            Header: 'Actions',
            width: 100,
            Cell: (cell) => {
                const row = cell.row.original;

                return (
                    <>
                        <FaRegFilePdf
                            className="actions"
                            title="View in PDF"
                            onClick={() => {
                                updateStateValue(setFilters, 'isLoading', true);
                                //get pdf export file and open it in a new window
                                apiClient(`/pdfExportEquipmentServicesById/${row.id}`, {
                                    method: 'GET',
                                    responseType: 'blob' //Force to receive data in a Blob Format
                                })
                                    .then(response => {
                                        //Create a Blob from the PDF Stream
                                        const file = new Blob(
                                            [response.data],
                                            {type: 'application/pdf'});
                                        //Build a URL from the file
                                        const fileURL = URL.createObjectURL(file);
                                        //Open the URL on new Window
                                        window.open(fileURL);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    }).then(function () {
                                    updateStateValue(setFilters, 'isLoading', false);
                                });
                            }}
                        />
                        {' '}
                        <FaEnvelopeOpenText
                            className="actions"
                            title="Send Equipment Service"
                            onClick={() => {
                                setSelectedRow(row);
                                setSendReportModal({
                                    isOpen: true
                                });
                            }}
                        />
                    </>
                );

            }
        }
    ];
}

export default EquipmentServicesTable;

