import React, {useState, useEffect} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import {isEmpty} from "lodash"
import TableReport from "../../../common/table/table";
import Table from "./operationDashboardDowntimeTargetTable";
import ModalConfirmation from "../../../common/modal/ModalConfirmation";
import {removeObjFromArray} from "../../../../utils/arrayHelpers";
import OperationDashboardDowntimeTargetCreateModal from "./operationDashboardDowntimeTargetCreateModal";

const OperationDashboardDowntimeTarget = () => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [formModal, setFormModal] = useState({isOpen: false, action: 'create'});
    const [deleteModal, setDeleteModal] = useState({isOpen: false, message: <p></p>});

    // delete function
    const deleteApprover = () => {
        apiClient
            .delete('adminDowntimeTargets/' + selectedRow.id)
            .then(response => {
                const newTableData = removeObjFromArray(tableData, "id", response.data.id)
                setTableData(newTableData);
                toast.success(response.data.message);
            })
            .catch(function () {
                //In case of error do nothing!!
                //let apiClient to handle the error
            })
            .then(function () {
                //close deactivateModal
                setDeleteModal({...deleteModal, isOpen: false})
            });
    }

    useEffect(() => {
        apiClient
            .get('adminDowntimeTargets')
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    return;
                }

                setTableData(response.data.records);
            });
    }, [setTableData]);

    const sortedTableData = tableData.sort((r1, r2) => r1.year - r2.year);

    return (
        <>
            <Breadcrumb title="Ops Dashboard Target" parent="Admin"/>
            <div className="container-fluid">
                <TableReport
                    columns={Table(setSelectedRow, setFormModal, setDeleteModal)}
                    data={sortedTableData}
                    getExportFileName={() => 'ops_dashboard_targets'}
                    exportOptions={{pdf: false, xlsx: true, csv: true}}
                    withGlobalSearch={true}
                    scrollable={true}
                    isSortDisabled
                />
                <OperationDashboardDowntimeTargetCreateModal
                    title="Add target"
                    modal={formModal}
                    setModal={setFormModal}
                    tableData={sortedTableData}
                    setTableData={setTableData}
                />
                <ModalConfirmation
                    modal={deleteModal}
                    setModal={setDeleteModal}
                    title="Delete target"
                    onConfirm={deleteApprover}
                />
            </div>
        </>
    );
}

export default OperationDashboardDowntimeTarget;
