import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import moment from "moment";

/**
 * Filter component for operations summary
 * @returns {JSX.Element}
 * @constructor
 */
const OperationsSummaryFilters = (prop) => {
    const {tableData, setTableData, setReportFilters, setChartsData} = prop;
    const {register, handleSubmit, errors, setValue} = useForm();
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [payPeriods, setPayPeriods] = useState([]);
    const [endDate, setEndDate] = useState(null);
    const [operationsUsers, setOperationsUsers] = useState([]);
    const [disciplineId, setDisciplineId] = useState(0);

    // submit handler for report filter
    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        const filters = {
            date_from: data.date_from,
            date_to: data.date_to,
            operations_user_id: Number(data.operations_user),
            discipline_id: disciplineId,
        }

        apiClient
            .post('getOperationsSummary', filters)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableData([]);
                    setChartsData(null);
                    return;
                }

                setTableData(response.data);
                setChartsData(response.data);
            })
            .finally(function () {
                updateStateValue(setReportFilters, 'isLoading', false);
            }
        );
    }

    useEffect(() => {
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                let year = Number(moment(response.data.end_date).format('YYYY'));
                setEndDate(year);
            });

        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => ({
                            id: id, name: name + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });

        apiClient
            .get('getOperationSummaryUsers')
            .then(response => {
                setOperationsUsers(
                    response.data.map(
                        ({id, first_name, last_name}) => ({
                            id: id, name: `${first_name} ${last_name}`
                        })
                    )
                );
            })

    }, []);

    useEffect(() => {
        if (endDate === null) {
            return;
        }
        apiClient
            .post('getPayPeriodsByYearPlusPreviousYear', {'year': endDate})
            .then(response => {
                setPayPeriods(
                    response.data.map(
                        ({id, start_date, end_date, is_current}) =>
                            ({id: id, name: start_date + ' - ' + end_date + (is_current ? ' (Current)' : '')})
                    )
                );
            });
    }, [endDate])

    const setDateByFinancialPeriod = (value) => {
        if (value) {
            apiClient
                .post('getFinancialPeriodById', {'id': value})
                .then(response => {
                    let startDate = response.data.start_date;
                    let endDate = response.data.end_date;
                    document.getElementById('date_from').value = startDate;
                    document.getElementById('date_to').value = endDate;

                    setValue('pay_period_id', null);
            });
        }
    }

    const setDateByPayPeriod = (value) => {
        if (value) {
            apiClient
                .post('getPayPeriodsById', {'id': value})
                .then(response => {
                    let start = response.data.start_date;
                    let end = response.data.end_date;
                    let starRaw = new Date(start);
                    let endRaw = new Date(end);
                    let startDate = moment(starRaw).format('yyyy-MM-DD');
                    let endDate = moment(endRaw).format('yyyy-MM-DD');

                    document.getElementById('date_from').value = startDate;
                    document.getElementById('date_to').value = endDate;

                    setValue('financial_period_id', null);
                });
        }
    }

    const resetPeriodFilters = () => {
        setValue('pay_period_id', null);
        setValue('financial_period_id', null);
    }

    const setUsersDiscipline = (id) => {
        apiClient
            .post('getDisciplineIdByUserId', {'id': id})
            .then(response => {
                setDisciplineId(response.data?.discipline_id);
            });

    }

    return (
        <form
            className="needs-validation s-filters-form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="s-filters">
                <FormSelect
                    id="pay_period_id"
                    label="Weekly Data Period"
                    className="s-filter"
                    options={payPeriods}
                    register={register}
                    onChange={(e) => setDateByPayPeriod(e.target.value)}
                    errors={errors}
                />
                <FormSelect
                    id="financial_period_id"
                    label="Monthly Data Period"
                    className="s-filter"
                    options={financialPeriods}
                    register={register}
                    onChange={(e) => setDateByFinancialPeriod(e.target.value)}
                    errors={errors}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="s-filter"
                    value={moment().subtract(7, "day").format("YYYY-MM-DD")}
                    register={register({required: "required"})}
                    onChange={() => resetPeriodFilters()}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="s-filter"
                    value={moment().format("YYYY-MM-DD")}
                    register={register({required: "required"})}
                    onChange={() => resetPeriodFilters()}
                />
                <FormSelect
                    id="operations_user"
                    label="Operations Manager/Foreman"
                    className="s-filter"
                    options={operationsUsers}
                    register={register({required: "required"})}
                    onChange={(e) => setUsersDiscipline(e.target.value)}
                    errors={errors}
                />            
                <FormSubmitButton
                    label="Show"
                    className="s-button s-button-xl"
                />
                
                {/* For mobile view */}
                {!isEmpty(tableData) ? (
                    <div className="s-buttons s-justify-between">
                        <FormSubmitButton
                            label="Show"
                            className="s-button"
                        />
                    </div>
                ) : (
                    <div className="s-buttons s-justify-end">
                        <FormSubmitButton
                            label="Show"
                            className="s-button"
                        />
                    </div>
                )}
            </div>
        </form>
    );
}

export default OperationsSummaryFilters;
