import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {isUndefined} from 'lodash';
import {getCurrentPeriodFromArray} from "../../../../utils/arrayHelpers";

/**
 * Filter component for Cost Code Report
 * @returns {JSX.Element}
 * @constructor
 */
const CostCodesReportFilters = (prop) => {
    const { setIsLoading } = prop;
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const {register, handleSubmit, setValue, errors} = useForm();
    const [clients, setClients] = useState([]);

    // get list of financial periods
    useEffect(() => {
        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => ({
                            id: id, name: name + (is_current ? ' (Current)' : '')
                        })
                    )
                );
        });

        apiClient
            .get('getActiveClientsList')
            .then(response => {
                setClients(
                    response.data.activeClientsList.map(
                        ({id, client_name}) => ({id: id, name: client_name})
                    )
                );
            });

    }, [setFinancialPeriods, setClients]);

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            const defaultFinancialPeriod = getCurrentPeriodFromArray(financialPeriods);
            if (!isUndefined(defaultFinancialPeriod)) {
                setValue('financial_period_id', defaultFinancialPeriod.id);
            }
        }, 400);
    }, [financialPeriods, setValue]);

    // download handler
    const onDownload = (data) => {
        setIsLoading(true);

        //get export file
        apiClient('exportCostCodesReport', {
            method: 'POST',
            responseType: 'blob', //Force to receive data in a Blob Format
            data: data
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'cost_codes_report.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }).then(function () {
                setIsLoading(false);
            }).catch(function () {
        });
    }


    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onDownload)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="financial_period_id"
                    label="Monthly Data Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={financialPeriods}
                    register={register}
                    onChange={() => {
                        setValue('date_from', '');
                        setValue('date_to', '');
                    }}
                    errors={errors}
                />
                <FormSelect
                    id="client_id"
                    label="Client"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                    options={clients}
                    register={register}
                    errors={errors}
                />
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    onChange={() => {
                        setValue('financial_period_id', '');
                    }}
                    register={register}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    onChange={() => {
                        setValue('financial_period_id', '');
                    }}
                    register={register}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Download"
                />
            </div>
        </form>
    );
}

export default CostCodesReportFilters;
