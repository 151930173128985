import {FaTrash} from "react-icons/fa";
import React from "react";

const EqServiceDistributionEmailListTableColumns = (setSelectedRow, setDeleteModal) => {
    return [
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Actions',
            Cell: (cell) => {
                const row = cell.row.original;
                return (
                    <>
                        <FaTrash
                            color='#A51E37'
                            title="Remove"
                            onClick={() => {
                                const msg = <p>Are you sure that you want to remove <b>{row.email}</b> from the list?</p>;
                                setSelectedRow(row);
                                setDeleteModal({
                                    message: msg,
                                    isOpen: true,
                                })
                            }}
                        />
                    </>
                )
            }
        }
    ];
}

export default EqServiceDistributionEmailListTableColumns;

