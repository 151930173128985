import React, {useEffect, useState} from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormInputText from "../../../forms/form-control/FormInputText";
import {useForm} from "react-hook-form";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormInputNumber from "../../../forms/form-control/FormInputNumber";
import apiClient from "../../../../utils/apiClient";
import {toast} from "react-toastify";
import {replaceObjFromArray} from "../../../../utils/arrayHelpers";
import FormInputDate from "../../../forms/form-control/FormInputDate";
import FormInputTextArea from "../../../forms/form-control/FormInputTextArea";
import moment from "moment";
import FormNoteAlert from "../../../forms/form-control/FormNoteAlert";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import FormCancelButton from "../../../forms/form-control/FormCancelButton";
import FormSearchableSelect from "../../../forms/form-control/FormSearchableSelect";
import * as Shared from "../../../common/shared";

/**
 * EditShopTimeEntryModal
 * @returns {JSX.Element}
 * @constructor
 */
const EditShopTimeEntryModal = (props) => {
    const {modal, setModal, selectedRow, tableData, setTableData} = props;
    const {register, control, handleSubmit, errors, setValue, getValues, watch} = useForm();
    const [payPeriod, setPayPeriod] = useState({});
    const [users, setUsers] = useState([]);
    const [ecOptions, setEcOptions] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [costCodes, setCostCodes] = useState([]);
    const [periodLockedStatus, setPeriodLockedStatus] = useState(false);
    const REGULAR_HOURS = 1;
    const EQUIPMENT_SERVICES_COST_CODE_ID = 24;

    useEffect(() => {
        // get period status
        apiClient
            .get('isLocked')
            .then(response => {
                setPeriodLockedStatus(response.data);
            });

        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                setPayPeriod(response.data)
            });

        apiClient
            .get('getShoEmployees')
            .then(response => {
                setUsers(
                    response.data.map(
                        ({id, first_name, last_name}) =>
                            ({value: id, label: first_name + ' ' + last_name})
                    )
                );
            });

        //get EC Options
        apiClient
            .get('getEcOptionsForShop')
            .then(response => {
                setEcOptions(response.data);
            });

        apiClient
            .get('getAllAssets')
            .then(response => {
                setEquipments(
                    response.data.map(
                        ({id, make, model, description, equipment_number}) =>
                            ({value: id, label: equipment_number + ': ' + make + ' ' + model + ' ' + description})
                    )
                );
            });

        //get active jobs
        apiClient
            .get('getAllActiveJobs')
            .then(response => {
                setJobs(
                    response.data.jobs.map(
                        ({id, job_number}) => ({id: id, name: job_number})
                    )
                );
            });

        //get cost codes for ML revenue group
        apiClient
            .post('getCostCodeByRevenueGroupId', {'revenue_group_id': 12})
            .then(response => {
                setCostCodes(
                    response.data.map(
                        ({id, description}) => ({value: id, label: description})
                    )
                );
            });
    }, []);

    const onSave = (data) => {
        const isEquipmentServicesCostCode = data.cost_code_id?.value === EQUIPMENT_SERVICES_COST_CODE_ID;

        apiClient
            .post('editShopTimeEntry', {
                ...data,
                eq_hours: isEquipmentServicesCostCode ? data.eq_hours : '',
                comp_hours: isEquipmentServicesCostCode ? data.comp_hours : '',
                truck_hours: isEquipmentServicesCostCode ? data.truck_hours : '',
                miles: isEquipmentServicesCostCode ? data.miles : '',
            })
            .then(response => {
                setTableData(
                    replaceObjFromArray(
                        tableData, "id", response.data.id, response.data.record
                    )
                );
                toast.success(response.data.message);
                toggle();
            });
    }

    const costCodeOption = watch('cost_code_id');

    // toggle for open/close the modal form
    const toggle = () => setModal({...modal, isOpen: !modal.isOpen});

    return (
        <Modal isOpen={modal.isOpen} toggle={toggle} className="modal-body modal-lg" centered={true} size={'lg'}>
            <form
                className="needs-validation"
                noValidate=""
                onSubmit={handleSubmit(onSave)}
            >
                <ModalHeader toggle={toggle}>Edit Shop Time Entry</ModalHeader>
                <ModalBody>
                    <FormNoteAlert
                        className={(periodLockedStatus) ? "alert-danger" : "alert-success"}
                        label={
                            (periodLockedStatus)
                                ?
                                    'Pay Period is Locked'
                                :
                                    'Current Pay Period: ' +
                                    moment(new Date(payPeriod.start_date)).format('MM/DD/YYYY') + ' - '
                                    +    moment(new Date(payPeriod.end_date)).format('MM/DD/YYYY')
                        }
                    />
                    <div className="form-row">
                        <FormInputText
                            id="id"
                            type="hidden"
                            value={selectedRow.id}
                            register={register}
                        />
                        <FormInputText
                            id="ec_id"
                            type="hidden"
                            value={selectedRow.ec_id}
                            register={register}
                        />
                        <FormSearchableSelect
                            id="user_id"
                            label="Shop Employee"
                            className="col-md-7"
                            options={users}
                            value={selectedRow?.user_id}
                            rules={{required: "required"}}
                            isDisabled={true}
                            control={control}
                            errors={errors}
                        />
                        <FormInputDate
                            id="date"
                            label="Date"
                            className="col-md-3"
                            min={moment(new Date(payPeriod.start_date)).subtract(7,'d').format('YYYY-MM-DD')}
                            max={moment(new Date(payPeriod.end_date)).format('YYYY-MM-DD')}
                            value={selectedRow.date}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                        <FormSelect
                            id="ec_id_display"
                            label="Reason"
                            className="col-md-3"
                            options={ecOptions}
                            value={selectedRow.ec_id}
                            disabled
                            register={register}
                            errors={errors}
                        />
                        <FormInputNumber
                            label="Hours"
                            id="hours"
                            className="col-md-2"
                            register={register({
                                required: {value: true, message: 'required'},
                                min: {value: 0, message: "min value is 0"},
                                max: {value: 24.01, message: "max value is 24.00"}
                            })}
                            onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                            value={selectedRow.hours}
                            errors={errors}
                        />
                    </div>
                    <div className="form-row">
                        <FormInputNumber
                            id="per_diem"
                            label="Per Diem"
                            className="col-md-2"
                            type="text"
                            value={selectedRow.per_diem}
                            register={register}
                        />
                        {selectedRow.ec_id === REGULAR_HOURS && getValues('cost_code_id') && modal.isOpen
                            ?
                                <FormSearchableSelect
                                    id="equipment_id"
                                    label="Equipment"
                                    className="col-md-7 mb-3"
                                    options={equipments}
                                    value={selectedRow.equipment_id}
                                    rules={{required: "required"}}
                                    control={control}
                                    onChange={() => {
                                        setValue('job_id', '');
                                    }}
                                    errors={errors}
                                />
                            :

                                (selectedRow.ec_id === REGULAR_HOURS)
                                ?
                                    <FormSearchableSelect
                                        id="equipment_id"
                                        label="Equipment"
                                        className="col-md-7 mb-3"
                                        options={equipments}
                                        value={selectedRow.equipment_id}
                                        rules={{required: false}}
                                        control={control}
                                        onChange={() => {
                                            setValue('job_id', '');
                                        }}
                                        errors={errors}
                                    />
                                :
                                    <></>
                        }
                    </div>
                    <div className="form-row">
                        { selectedRow.ec_id === REGULAR_HOURS && getValues('equipment_id') && modal.isOpen
                            ?
                                <FormSearchableSelect
                                    id="cost_code_id"
                                    label="Cost Code"
                                    className="col-md-7 mb-3"
                                    rules={{required: "required"}}
                                    options={costCodes}
                                    value={selectedRow.cost_code_id}
                                    control={control}
                                    onChange={() => {
                                        setValue('job_id', '');
                                    }}
                                    errors={errors}
                                />
                            :
                                (selectedRow.ec_id === REGULAR_HOURS)
                                ?
                                    <FormSearchableSelect
                                        id="cost_code_id"
                                        label="Cost Code"
                                        className="col-md-7 mb-3"
                                        rules={{required: false}}
                                        options={costCodes}
                                        value={selectedRow.cost_code_id}
                                        control={control}
                                        onChange={() => {
                                            setValue('job_id', '');
                                        }}
                                        errors={errors}
                                    />
                                :
                                    <></>
                        }
                        { selectedRow.ec_id === REGULAR_HOURS &&
                            <FormSelect
                                id="job_id"
                                label="Job"
                                className="col-md-5 mb-3"
                                options={jobs}
                                value={selectedRow.job_id}
                                register={register}
                                onChange={() => {
                                    setValue('cost_code_id', '');
                                    setValue('equipment_id', '');
                                }}
                                errors={errors}
                            />
                        }
                    </div>
                    {
                        costCodeOption?.value === EQUIPMENT_SERVICES_COST_CODE_ID && modal.isOpen && (
                            <>
                                <div className="form-row">
                                    <FormInputNumber
                                        label="Eq. Hours"
                                        id="eq_hours"
                                        className="col-md-2"
                                        register={register({required: false})}
                                        onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                                        value={selectedRow.eq_hours}
                                        errors={errors}
                                    />
                                    <FormInputNumber
                                        label="Comp Hours"
                                        id="comp_hours"
                                        className="col-md-2"
                                        register={register({required: false})}
                                        onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                                        value={selectedRow.comp_hours}
                                        errors={errors}
                                    />
                                    <FormInputNumber
                                        label="Truck Hours"
                                        id="truck_hours"
                                        className="col-md-2"
                                        register={register({required: false})}
                                        onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                                        value={selectedRow.truck_hours}
                                        errors={errors}
                                    />
                                    <FormInputNumber
                                        label="Miles"
                                        id="miles"
                                        className="col-md-2"
                                        register={register({required: false})}
                                        onInput={(e) => Shared.limitInputDaily(e.target, 5)}
                                        value={selectedRow.miles}
                                        errors={errors}
                                    />
                                </div>
                            </>
                        )
                    }
                    <div className="form-row">
                        <FormInputTextArea
                            id="notes"
                            label="Work Description"
                            className="col-md-12 mb-3"
                            rows="3"
                            cols="45"
                            value={selectedRow.notes}
                            register={register({required: "required"})}
                            errors={errors}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="form-row">
                        <FormSubmitButton
                            disabled={periodLockedStatus}
                            className="col-md-6"
                            label="Save"
                        />
                        <FormCancelButton
                            className="col-md-5"
                            label="Cancel"
                            onClick={toggle}
                        />
                    </div>
                </ModalFooter>
            </form>
        </Modal>
    );
}

export default EditShopTimeEntryModal;
