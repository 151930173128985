import React from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import {Bar} from 'react-chartjs-2';
import ChartContainer from './chartContainer';
import {formatNumberToDisplayWithCommas} from '../../../../../components/common/shared';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin);

const RevenuePerShiftChart = ({chartData}) => {

    const dataset = chartData.items;
    const labels = chartData.items.map(item => item.date);
    const avgRollingDataset = chartData.items.map(item => item.avg_rolling_30_days);
    const targetDataset = chartData.items.map(item => item.target)

    const data = {
        labels: labels,
        datasets: [
            {
                type: 'bar',
                data: dataset,
                backgroundColor: '#bfbfbf',
                order: 2,
                label: 'Rev Per Shift',
                parsing: {
                    xAxisKey: 'date',
                    yAxisKey: 'avg_revenue'
                },
            },
            {
                type: 'line',
                data: avgRollingDataset,
                backgroundColor: '#000000',
                borderColor: '#000000',
                order: 0,
                label: 'Rolling 30-Day Avg',
            },
            {
                type: 'line',
                data: targetDataset,
                backgroundColor: '#c00000',
                borderColor: '#c00000',
                order: 1,
                label: 'Target',
            }
        ],
    }

    const options = {
        scales: {
            y: {
                title: 'Revenue Per Shift',
                beginAtZero: true,
                ticks: {
                    callback: function(value) {
                        return `$${value}`;
                    }
                }
            },
            x: {
                grid: {
                    drawOnChartArea: false,
                    tickLength: 10,
                },
                ticks: {
                    autoSkip: false,
                    callback: (_, i) => i % 7 ? '' : labels[i]
                }
            },
        },
        plugins: {
            legend: {
                reverse: true,
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `$${formatNumberToDisplayWithCommas(context.parsed.y, 0)}`;
                        }
                        return label;
                    }
                }
            }
        },
        maxBarThickness: 20,
    }

    const summaryItems = [
        {
            label: 'Monthly Avg',
            value: `$${formatNumberToDisplayWithCommas(chartData.monthly_avg, 0)}`,
        },
        {
            label: 'Rolling 30-Day Avg',
            value: `$${formatNumberToDisplayWithCommas(chartData.rolling_30_days_avg, 0)}`,
        },
        {
            label: 'Current Target',
            value: `$${formatNumberToDisplayWithCommas(chartData.current_target, 0)}`,
        },
    ]

    return (
        <ChartContainer title="Revenue Per Shift" summaryItems={summaryItems}>
            <Bar data={data} options={options}/>
        </ChartContainer>
    )
}

export default RevenuePerShiftChart;
