import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from 'lodash';
import moment from "moment";

/**
 * Filter component for ToolPusher Toolbox Report
 * @returns {JSX.Element}
 * @constructor
 */
const ToolPusherToolboxFilters = (prop) => {
    const {
        setReportFilters, setTableDataDiscipline1, setTableDataDiscipline2, setTableDataDiscipline3,
        setTableDataDiscipline4, setStartDate, setEndDate, setPeriodId
    } = prop;
    const {register, handleSubmit, setValue, errors, watch} = useForm();
    const filterIDs = ['pay_period_id', 'financial_period_id', 'ytd_id']
    const payPeriodId = watch('pay_period_id');
    const financialPeriodId = watch('financial_period_id');
    const [endDateYear, setEndDateYear] = useState(0);

    useEffect(() => {
        setPeriodId(
            payPeriodId
                ?
                    {
                        id: payPeriodId,
                        type: 'pay_period'
                    }
                :
                    {
                        id: financialPeriodId,
                        type: 'financial_period'
                    }
        )
    }, [setPeriodId, payPeriodId, financialPeriodId])

    const [payPeriod, setPayPeriod] = useState({});
    const [financialPeriods, setFinancialPeriods] = useState([]);
    const [payPeriods, setPayPeriods] = useState([]);

    const onInitAndChange = (id) => {
        filterIDs.forEach((filterID) => {
            if (id === filterID) {
                return;
            }
            setValue(filterID, '');
        })
    }

    const onSubmit = (data) => {
        updateStateValue(setReportFilters, 'isLoading', true);
        updateStateValue(setReportFilters, 'isSubmitted', true);

        apiClient
            .post('getToolPusherToolbox', data)
            .then(response => {
                if (isEmpty(response.data)) {
                    setTableDataDiscipline1([]);
                    setTableDataDiscipline2([]);
                    setTableDataDiscipline3([]);
                    setTableDataDiscipline4([]);
                    setStartDate([]);
                    setEndDate([]);
                    return;
                }
                setTableDataDiscipline1(response.data.core_surface);
                setTableDataDiscipline2(response.data.core_underground);
                setTableDataDiscipline3(response.data.rcx);
                setTableDataDiscipline4(response.data.rotary);
                setStartDate(response.data.start_date);
                setEndDate(response.data.end_date);
            })
            .catch(function () {
                setTableDataDiscipline1([]);
                setTableDataDiscipline2([]);
                setTableDataDiscipline3([]);
                setTableDataDiscipline4([]);
                setStartDate([]);
                setEndDate([]);
            }).then(function () {
            updateStateValue(setReportFilters, 'isLoading', false);
        });
    }

    // get list of financial periods and pay peridos
    useEffect(() => {
        // get current pay period
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                setPayPeriod(response.data);
                let year = Number(moment(response.data.end_date, 'MM-DD-YY').format('YYYY'));
                setEndDateYear(year);
            });

        apiClient
            .get('getFinancialPeriods')
            .then(response => {
                setFinancialPeriods(
                    response.data.map(
                        ({id, name, is_current}) => ({
                            id: id, name: name + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });

        apiClient
            .post('getPayPeriodsByYearPlusPreviousYear', {'year': endDateYear})
            .then(response => {
                setPayPeriods(
                    response.data.map(
                        ({id, start_date, end_date, is_current}) => ({
                            id: id, name: start_date + ' - ' + end_date + (is_current ? ' (Current)' : '')
                        })
                    )
                );
            });

    }, [setFinancialPeriods, setPayPeriods, endDateYear]);

    useEffect(() => {
        //check if financial period was selected
        setTimeout(() => {
            updateStateValue(setReportFilters, 'pay_period_id', payPeriod.id);
            setValue('pay_period_id', payPeriod.id);
        }, 200);
    }, [payPeriod, setReportFilters, setValue]);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormSelect
                    id="pay_period_id"
                    label="Weekly Data Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-3"
                    options={payPeriods}
                    register={register}
                    errors={errors}
                    onChange={() => onInitAndChange('pay_period_id')}
                />
                <FormSelect
                    id="financial_period_id"
                    label="Monthly Data Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    options={financialPeriods}
                    register={register}
                    onChange={() => onInitAndChange('financial_period_id')}
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default ToolPusherToolboxFilters;