import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../../utils/apiClient";
import FormSelect from "../../../forms/form-control/FormSelect";
import FormSubmitButton from "../../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../../utils/stateHelpers";
import {isEmpty} from "lodash";
import {FaSearch} from "react-icons/fa";

/**
 * ShopTimeEntryToReviewFilter component
 * @returns {JSX.Element}
 * @constructor
 */
const ShopTimeEntryToApproveFilter = (prop) => {
    const {
        setFilters, setHoursTableRows, setHoursTableHeader, setSelectedRow, setApproveModal, setPerDiemTableHeader,
        setPerDiemTableRows, OnSubmitUrl
    } = prop;
    const {register, handleSubmit, errors, watch, setValue} = useForm();
    const [payPeriods, setPayPeriods] = useState([]);
    const [endDate, setEndDate] = useState(0);

    const pay_period_id = watch('pay_period_id');

    // this part will be added to hours table header
    const actions = ({
        Header: 'Actions',
        Cell: (cell) => {
            const row = cell.row.original;
            return (
                <>
                    <FaSearch
                        className="actions"
                        size={18}
                        title="Review"
                        onClick={() => {
                            setSelectedRow(row);
                            setApproveModal({
                                isOpen: true
                            });
                        }}
                    />
                </>
            );
        }
    });

    // get list of pay periods
    useEffect(() => {
        apiClient
            .get('getCurrentPayPeriod')
            .then(response => {
                if (typeof response.data !== 'undefined') {
                    // it is ugly but safari on mobile device doesn't want to operate with this date format
                    // temporary solution for now until time entry mobile app will be build
                    // do not remove commented out code!
                    const endDateArray = response.data.end_date.split('-');

                    // let year = Number(moment(new Date(response.data.end_date)).format('YYYY'));
                    // let year = new Date(response.data.end_date).getFullYear();
                    let year = 2000 + Number(endDateArray[2]);
                    setEndDate(year);
                }
            });

        apiClient
            .post('getPayPeriodsByYearPlusPreviousYear', {'year': endDate})
            .then(response => {
                setPayPeriods(
                    response.data?.map(
                        ({id, start_date, end_date, is_current}) => {

                            if (is_current) {
                                setValue('pay_period_id', id);
                            }

                            return {
                                id: id, name: start_date + ' - ' + end_date + (is_current ? ' (Current)' : '')
                            }
                        }                            
                    )
                );
            });
    }, [endDate, setValue]);

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        apiClient
            .post(OnSubmitUrl, data)
            .then(response => {
                if (isEmpty(response.data.data)) {
                    setHoursTableRows([]);
                    setPerDiemTableRows([]);
                    return;
                }

                let hoursTableHeader = response.data.data.tableHoursHeader;
                // add actions to the header
                hoursTableHeader.push(actions);
                setHoursTableHeader(hoursTableHeader);
                setHoursTableRows(response.data.data.tableHoursRows);
                setPerDiemTableHeader(response.data.data.tablePerDiemHeader);
                setPerDiemTableRows(response.data.data.tablePerDiemRows);
            })
            .catch(function () {
                setHoursTableRows([]);
                setHoursTableHeader([]);
                setPerDiemTableRows([]);
                setPerDiemTableHeader([]);
            }).then(function () {
            updateStateValue(setFilters,'isLoading', false);
        });
    }

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row">
                <FormSelect
                    id="pay_period_id"
                    label="Pay Period"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={pay_period_id}
                    options={payPeriods}
                    onChange={(e) => setValue('pay_period_id', e.target.value)}
                    register={register('pay_period_id', {required: "required"})}
                    isControlled
                    errors={errors}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default ShopTimeEntryToApproveFilter;
