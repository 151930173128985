import React, {useMemo, useState} from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import {Bar} from 'react-chartjs-2';
import ChartContainer from '../chartContainer';
import { hideTooltipForDatasetsPlugin } from '../../../../../../utils/hideTooltipForDatasets';
import {DowntimeActivitiesTooltip} from './downtimeActivitiesTooltip';
import { createPortal } from 'react-dom';
import {formatNumberToDisplayWithCommas} from '../../../../../../components/common/shared';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin, hideTooltipForDatasetsPlugin);

const DowntimeChart = ({chartData, withSummaryTooltip = false}) => {
    const [tooltipData, setTooltipData] = useState(null);

    const dataset = useMemo(() => chartData.items, [chartData.items]);
    const labels = useMemo(() => chartData.items.map(item => item.date), [chartData.items]);
    const avgRollingDataset = useMemo(() => chartData.items.map(item => item.avg_rolling_30_days_percentage), [chartData.items]);
    const targetDataset = useMemo(() => chartData.items.map(item => item.target_percentage), [chartData.items]);

    const data = useMemo(() => ({
        labels: labels,
        datasets: [
            {
                type: 'bar',
                data: dataset,
                backgroundColor: '#bfbfbf',
                order: 2,
                label: 'Downtime',
                parsing: {
                    xAxisKey: 'date',
                    yAxisKey: 'percentage'
                },
            },
            {
                type: 'line',
                data: avgRollingDataset,
                backgroundColor: '#000000',
                borderColor: '#000000',
                order: 0,
                label: 'Rolling 30-Day Avg',
            },
            {
                type: 'line',
                data: targetDataset,
                backgroundColor: '#c00000',
                borderColor: '#c00000',
                order: 1,
                label: 'Target',
            }
        ],
    }), [avgRollingDataset, dataset, labels, targetDataset]);

    const options = useMemo(() => ({
        scales: {
            y: {
                title: 'Downtime',
                beginAtZero: true,
                ticks: {
                    callback: function(value) {
                        return `${value}%`;
                    }
                }
            },
            x: {
                grid: {
                    drawOnChartArea: false,
                    tickLength: 10,
                },
                ticks: {
                    autoSkip: false,
                    callback: (_, i) => i % 7 ? '' : labels[i]
                }
            },
        },
        plugins: {
            legend: {
                reverse: true,
            },
            tooltip: {
                animation: false,
                hideForDatasetIndexes: withSummaryTooltip ? [0] : undefined,
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `${formatNumberToDisplayWithCommas(context.parsed.y, 1)}%`;
                        }
                        return label;
                    }
                },
                external: ({tooltip, chart}) => {
                    if (!withSummaryTooltip) {
                        return;
                    }
                    if (tooltip.dataPoints.length === 1 && tooltip.dataPoints[0].datasetIndex === 0) {
                        if (tooltip.opacity === 0) {
                            setTooltipData(null);
                            return;
                        }
                        
                        const {left: positionX, top: positionY} = chart.canvas.getBoundingClientRect();
                        const dataIndex = tooltip.dataPoints[0].dataIndex;
                        const activitiesData = tooltip.dataPoints[0].dataset.data[dataIndex].downtime_activities;
                        setTooltipData({
                            x: positionX + tooltip.caretX,
                            y: positionY + tooltip.caretY + document.documentElement.scrollTop,
                            activitiesData,
                        })
                    } else {
                        setTooltipData(null);
                    }
                }
            },
        },
        maxBarThickness: 20,
    }), [labels, withSummaryTooltip])

    const summaryItems = useMemo(() => [
        {
            label: 'Monthly Avg',
            value: `${formatNumberToDisplayWithCommas(chartData.monthly_avg, 1)}%`,
        },
        {
            label: 'Rolling 30-Day Avg',
            value: `${formatNumberToDisplayWithCommas(chartData.rolling_30_days_avg, 1)}%`,
        },
        {
            label: 'Current Target',
            value: `${formatNumberToDisplayWithCommas(chartData.current_target, 1)}%`,
        },
    ], [chartData.rolling_30_days_avg, chartData.monthly_avg, chartData.current_target]);

    return (
        <ChartContainer title="Downtime" summaryItems={summaryItems}>
            <Bar data={data} options={options}/>
            {tooltipData && createPortal(<DowntimeActivitiesTooltip data={tooltipData} />, document.body)}
        </ChartContainer>
    )
}

export default DowntimeChart;
