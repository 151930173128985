import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import apiClient from "../../../utils/apiClient";
import FormInputDate from "../../forms/form-control/FormInputDate";
import FormSubmitButton from "../../forms/form-control/FormSubmitButton";
import {updateStateValue} from "../../../utils/stateHelpers";
import FormSearchableSelect from "../../forms/form-control/FormSearchableSelect";

/**
 * DisciplineDateRangeFilter component
 * @returns {JSX.Element}
 * @constructor
 */
const DateRangeEquipmentFilter = (prop) => {
    const {register, handleSubmit, errors, control} = useForm();
    const {filters, setFilters, setTableData, OnSubmitUrl, isEquipmentRequired = true} = prop;
    const [assets, setAssets] = useState([]);

    const onSubmit = (data) => {
        updateStateValue(setFilters,'isLoading', true);
        updateStateValue(setFilters,'isSubmitted', true);

        let params = {
            'date_from': data['date_from'],
            'date_to': data['date_to'],
            'equipment_id': data['equipment_id']?.value,
        }

        apiClient
            .post(OnSubmitUrl, params)
            .then(response => {
                setTableData(response.data);
            })
            .catch(function () {})
            .then(function () {
                updateStateValue(setFilters,'isLoading', false);
            });
    }

    useEffect(() => {
        apiClient
            .get('getAllAssets')
            .then(response => {
                setAssets(
                    response.data.map(
                        ({id, equipment_number, make, model, description}) =>
                            ({value: id, label: equipment_number + ' ' +  make + ' ' + model + ' ' + description})
                    )
                );
            });
    }, []);

    return (
        <form
            className="needs-validation container-fluid"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="form-row s-gy">
                <FormInputDate
                    id="date_from"
                    label="From"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.date_from}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormInputDate
                    id="date_to"
                    label="To"
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    value={filters.date_to}
                    register={register({required: "required"})}
                    errors={errors}
                />
                <FormSearchableSelect
                    id="equipment_id"
                    label="Equipment ID"
                    options={assets}
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    rules={{required: isEquipmentRequired ? "required" : false}}
                    control={control}
                    errors={errors}
                    customStyles={{
                        menu: (provided) => ({
                            ...provided,
                            'z-index': 10000,
                        }),
                    }}
                />
                <FormSubmitButton
                    className="col-xs-12 col-sm-6 col-md-3 col-lg-2"
                    label="Show"
                />
            </div>
        </form>
    );
}

export default DateRangeEquipmentFilter;
